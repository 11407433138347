import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { FiTarget } from "react-icons/fi";
import { MdOutlineVisibility } from "react-icons/md";
import { FaHandshake } from "react-icons/fa";

import imgDefault from '../../assets/image/placeholder.png'


function Values_4({image, image1, image2}) {
  const { rpdata } = useContext(GlobalDataContext);

  const valuesData = [
    {
      title: rpdata?.dbValues?.[0].title,
      description: rpdata?.dbValues?.[0].description,
      icon: (
        <FiTarget fontSize={80} className="bg2 text-white corteromboclip p-6 " />
      ),
    },
    {
      title: rpdata?.dbValues?.[1].title,
      description: rpdata?.dbValues?.[1].description,
      icon: (
        <MdOutlineVisibility
          fontSize={80}
          className="bg2 text-white corteromboclip p-6 "
        />
      ),
    },
    {
      title: rpdata?.dbValues?.[2].title,
      description: rpdata?.dbValues?.[2].description,
      icon: (
        <FaHandshake
          fontSize={80}
          className="bg2 text-white corteromboclip p-6 "
        />
      ),
    },
  ];

  return (
    <>
    <div className="mt-14 mb-6 underline decoration-cyan-600">
        <h2 className="text-center text-black ">
        {rpdata?.dbSlogan?.[2].slogan}
        </h2>
    </div>
    <div className="w-full py-2 flex flex-wrap gap-4 justify-center text-center" >
        {valuesData.map((items, index) => {
            return (
                <div key={index} className="md:w-[30%] w-[90%] h-auto bg-center md:block border-t-4 border-cyan-600"
                    style={{ backgroundImage: `url("${rpdata ? image2 : imgDefault }")` }}
                    data-aos="zoom-in" data-aos-duration="1000">
                    <div className="bg-white bg-opacity-80 p-4 md:p-8 h-full flex flex-col justify-center items-center">
                        <div className="w-20 h-20 bg-2 flex items-center justify-center rounded-full corteromboclip">
                            {items.icon}
                        </div>
                        <h4 className="font-bold text-[20px] md:text-[30px] my-4">{items.title}</h4>
                        <p className="text-[14px] md:text-[16px]">{items.description}</p>
                    </div>
                </div>
            );
          })}
        </div>
        </>
  );
}

export default Values_4;